// // import { Flex, Box } from "@chakra-ui/react";
// // import {
// //   Grid,
// //   GridItem,
// //   Image,
// //   Button,
// //   Heading,
// //   useBreakpointValue,
// // } from "@chakra-ui/react";

// // export const GridPromoAfNov = () => {
// //   const columns = useBreakpointValue({ base: 1, md: 2 });

// //   const heightGrid = useBreakpointValue({
// //     base: "250px",
// //     lg: "400px",
// //   });

// //   const widthGrid = useBreakpointValue({
// //     base: "95%",
// //     lg: "95%",
// //   });

// //   const widthResponsiveHeading = useBreakpointValue({
// //     base: "100%",
// //     md: "75%",
// //     lg: "50%",
// //   });

// //   const contentAiFranceEconomy = [
// //     {
// //       title: "BOSTON(BOS)",
// //       price: "Începând de la 120 euro",
// //       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Boston.jpg",
// //     },
// //     {
// //       title: "NEW YORK (NYC)",
// //       price: "Începând de la 537 euro",
// //       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/New York.jpg",
// //     },
// //     {
// //       title: "LOS ANGELES(LAX)",
// //       price: "Începând de la 712 euro",
// //       thumbnail:
// //         "https://travel-time.ro/images/af-kl/AfEconomic/Los Angeles.jpg",
// //     },
// //     {
// //       title: "DENVER(DEN)",
// //       price: "Începând de la 320 euro",
// //       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Denver.jpg",
// //     },
// //     {
// //       title: "TORONTO(YTO)",
// //       price: "Începând de la 320 euro",
// //       thumbnail: "https://travel-time.ro/images/af-kl/AfPremium/Toronto.jpg",
// //     },
// //     {
// //       title: "PHOENIX(PHX)",
// //       price: "Începând de la 320 euro",
// //       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Phoenix.jpg",
// //     },
// //   ];

// //   return (
// //     <>
// //       {/* <Center
// //         className="centerText"
// //         borderBottom="1px solid"
// //         fontSize={textResponsive}
// //         fontWeight="bold"
// //         color="#0a1373"
// //         textShadow="2px 2px 4px rgba(0, 0, 0)"
// //         px="6"
// //         mb="6"
// //       >
// //         Air France Destination
// //       </Center> */}
// //       <Heading
// //         width={widthResponsiveHeading}
// //         mx="auto"
// //         color="#00d4ff"
// //         mt="7px"
// //         textAlign="center"
// //         size="lg"
// //         as="h1"
// //         mb={4}
// //       >
// //         <Image
// //           src="/images/af-kl/Air France-logo.png"
// //           alt="images"
// //           w="500px"
// //           mx="auto"
// //           objectFit="cover"
// //         />
// //       </Heading>

// //       <Flex mt="10px" flexWrap="wrap" justifyContent="center">
// //         <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
// //           {contentAiFranceEconomy.map((image) => {
// //             return (
// //               <Box key={image.id} mb={6}>
// //                 <GridItem position="relative" width={widthGrid} mx="auto">
// //                   <Box
// //                     className="description-title"
// //                     mx="auto"
// //                     p={2}
// //                     mb={3}
// //                     boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
// //                     display="flex"
// //                     justifyContent="center"
// //                     background="#0a1373"
// //                     color="white"
// //                     fontWeight="bold"
// //                     fontSize="1.2rem"
// //                   >
// //                     {image.title}
// //                   </Box>
// //                   <a href="https://wwws.airfrance.ro/travel-guide/destinations">
// //                     <Image
// //                       src={image.thumbnail}
// //                       alt={image.alt}
// //                       w="100%"
// //                       h={heightGrid}
// //                       objectFit="cover"
// //                     />
// //                   </a>

// //                   <a href="https://wwws.airfrance.ro/travel-guide/destinations">
// //                     <Button
// //                       position="absolute"
// //                       bottom={0}
// //                       left="50%"
// //                       transform="translateX(-50%)"
// //                       size="sm"
// //                       background="white"
// //                       // colorScheme="black"
// //                       color="black"
// //                       p={3}
// //                       mb={3}
// //                       opacity="0.9"
// //                       mr={3}
// //                       fontSize="1.2rem"
// //                       type="button"
// //                       border="1px"
// //                       // _hover={{ bg: "white", color: "black" }}
// //                     >
// //                       Călătorește acum cu Air France!
// //                     </Button>
// //                   </a>
// //                 </GridItem>
// //               </Box>
// //             );
// //           })}
// //         </Grid>
// //       </Flex>
// //     </>
// //   );
// // };

// import { Flex, Box } from "@chakra-ui/react";
// import {
//   Grid,
//   GridItem,
//   Image,
//   Button,
//   Center,
//   Heading,
//   useBreakpointValue,
// } from "@chakra-ui/react";

// export const GridPromoAfNov = () => {
//   const columns = useBreakpointValue({ base: 1, md: 2 });

//   const heightGrid = useBreakpointValue({
//     base: "250px",
//     lg: "400px",
//   });

//   const widthGrid = useBreakpointValue({
//     base: "95%",
//     lg: "95%",
//   });

//   const widthResponsiveHeading = useBreakpointValue({
//     base: "100%",
//     md: "75%",
//     lg: "50%",
//   });

//   const textResponsive = useBreakpointValue({
//     base: "20px",
//     lg: "35px",
//   });

//   const contentAiFranceEconomy = [
//     {
//       title: "NEW YORK (NYC)",
//       price: "Începând de la 528 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/New York.jpg",
//     },
//     {
//       title: "FORT DE FRANCE (FDF)",
//       price: "Începând de la 980 euro",
//       thumbnail:
//         "https://travel-time.ro/images/af-kl/AfEconomic/Fort-De-France.jpg",
//     },
//     {
//       title: "MIAMI(MIA)",
//       price: "Începând de la 712 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Miami.jpg",
//     },
//     {
//       title: "BOSTON(BOS)",
//       price: "Începând de la 525 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Boston.jpg",
//     },
//     {
//       title: "HONG KONG(HKG)",
//       price: "Începând de la 600 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Hong Kong.jpg",
//     },
//     {
//       title: "BEIJING(BJS)",
//       price: "Începând de la 552 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/AfPremium/Beijing.jpg",
//     },
//     {
//       title: "NAIROBI(NBO)",
//       price: "Începând de la 765 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Nairobi.jpg",
//     },
//     {
//       title: "PHOENIX(PHX)",
//       price: "Începând de la 730 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Phoenix.jpg",
//     },
//   ];

//   return (
//     <>
//       <Heading
//         width={widthResponsiveHeading}
//         mx="auto"
//         color="#00d4ff"
//         mt="7px"
//         textAlign="center"
//         size="lg"
//         as="h1"
//         mb={4}
//       >
//         <Image
//           src="/images/af-kl/Air France-logo.png"
//           alt="images"
//           w="500px"
//           mx="auto"
//           objectFit="cover"
//         />
//       </Heading>

//       <Center
//         className="centerText"
//         borderBottom="1px solid"
//         fontSize={textResponsive}
//         fontWeight="bold"
//         color="#0a1373"
//         textShadow="2px 2px 4px rgba(0, 0, 0)"
//         px="6"
//         mb="6"
//       >
//         Air France Economy Class
//       </Center>

//       <Flex mt="10px" flexWrap="wrap" justifyContent="center">
//         <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
//           {contentAiFranceEconomy.map((image) => {
//             return (
//               <Box key={image.id} mb={6}>
//                 <GridItem position="relative" width={widthGrid} mx="auto">
//                   <Box
//                     className="description-title"
//                     mx="auto"
//                     p={2}
//                     mb={3}
//                     boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
//                     display="flex"
//                     justifyContent="center"
//                     background="#0a1373"
//                     color="white"
//                     fontWeight="bold"
//                     fontSize="1.2rem"
//                   >
//                     {image.title}
//                   </Box>
//                   <a href="https://tbs.flytime.ro/reseller/auth/">
//                     <Image
//                       src={image.thumbnail}
//                       alt={image.alt}
//                       w="100%"
//                       h={heightGrid}
//                       objectFit="cover"
//                     />
//                   </a>

//                   <a href="https://tbs.flytime.ro/reseller/auth/">
//                     <Button
//                       position="absolute"
//                       bottom={0}
//                       left="50%"
//                       transform="translateX(-50%)"
//                       size="sm"
//                       background="white"
//                       // colorScheme="black"
//                       color="black"
//                       p={3}
//                       mb={3}
//                       opacity="0.9"
//                       mr={3}
//                       fontSize="1.2rem"
//                       type="button"
//                       border="1px"
//                       // _hover={{ bg: "white", color: "black" }}
//                     >
//                       {image.price}
//                     </Button>
//                   </a>
//                   {/* 
//                   <a href="https://wwws.airfrance.ro/travel-guide/destinations">
//                     <Button
//                       position="absolute"
//                       bottom={0}
//                       left="50%"
//                       transform="translateX(-50%)"
//                       size="sm"
//                       background="white"
//                       // colorScheme="black"
//                       color="black"
//                       p={3}
//                       mb={3}
//                       opacity="0.9"
//                       mr={3}
//                       fontSize="1.2rem"
//                       type="button"
//                       border="1px"
//                       // _hover={{ bg: "white", color: "black" }}
//                     >
//                       Călătorește acum cu Air France!
//                     </Button>
//                   </a> */}
//                 </GridItem>
//               </Box>
//             );
//           })}
//         </Grid>
//       </Flex>
//     </>
//   );
// };


// import { Flex, Box } from "@chakra-ui/react";
// import {
//   Grid,
//   GridItem,
//   Image,
//   Button,
//   Center,
//   Heading,
//   useBreakpointValue,
// } from "@chakra-ui/react";

// export const GridPromoAfNov = () => {
//   const columns = useBreakpointValue({ base: 1, md: 2 });

//   const heightGrid = useBreakpointValue({
//     base: "250px",
//     lg: "400px",
//   });

//   const widthGrid = useBreakpointValue({
//     base: "95%",
//     lg: "95%",
//   });

//   const widthResponsiveHeading = useBreakpointValue({
//     base: "100%",
//     md: "75%",
//     lg: "50%",
//   });

//   const textResponsive = useBreakpointValue({
//     base: "20px",
//     lg: "35px",
//   });

//   const contentAiFranceEconomy = [
//     {
//       title: "PARIS(CDG)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Paris.jpg",
//     },
//     {
//       title: "HELSINKI(HEL)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Helsinki.jpg",
//     },
//     {
//       title: "MADRID(MAD)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Madrid.jpg",
//     },
//     {
//       title: "BORDEAUX(BOD)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Bordeaux.jpg",
//     },
//     {
//       title: "TOULOUSE(TLS)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Toulouse.jpg",
//     },
//     {
//       title: "PORTO(OPO)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Porto.jpg",
//     },
//     {
//       title: "MARSEILLE(MRS)",
//       price: "Verifică acum!",
//       thumbnail:
//         "https://travel-time.ro/images/af-kl/af-feb-2025/Marseille.jpg",
//     },
//     {
//       title: "NICE(NCE)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Nice.jpg",
//     },
//   ];

//   return (
//     <>
//       <Heading
//         width={widthResponsiveHeading}
//         mx="auto"
//         color="#00d4ff"
//         mt="7px"
//         textAlign="center"
//         size="lg"
//         as="h1"
//         mb={4}
//       >
//         <Image
//           src="/images/af-kl/Air France-logo.png"
//           alt="images"
//           w="500px"
//           mx="auto"
//           objectFit="cover"
//         />
//       </Heading>

//       <Center
//         className="centerText"
//         borderBottom="1px solid"
//         fontSize={textResponsive}
//         fontWeight="bold"
//         color="#0a1373"
//         textShadow="2px 2px 4px rgba(0, 0, 0)"
//         px="6"
//         mb="6"
//       >
//         Air France Economy Class
//       </Center>

//       <Flex mt="10px" flexWrap="wrap" justifyContent="center">
//         <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
//           {contentAiFranceEconomy.map((image) => {
//             return (
//               <Box key={image.id} mb={6}>
//                 <GridItem position="relative" width={widthGrid} mx="auto">
//                   <Box
//                     className="description-title"
//                     mx="auto"
//                     p={2}
//                     mb={3}
//                     boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
//                     display="flex"
//                     justifyContent="center"
//                     background="#0a1373"
//                     color="white"
//                     fontWeight="bold"
//                     fontSize="1.2rem"
//                   >
//                     {image.title}
//                   </Box>
//                   <a href="https://tbs.flytime.ro/reseller/auth/">
//                     <Image
//                       src={image.thumbnail}
//                       alt={image.alt}
//                       w="100%"
//                       h={heightGrid}
//                       objectFit="cover"
//                     />
//                   </a>

//                   <a href="https://tbs.flytime.ro/reseller/auth/">
//                     <Button
//                       position="absolute"
//                       bottom={0}
//                       left="50%"
//                       transform="translateX(-50%)"
//                       size="sm"
//                       background="white"
//                       // colorScheme="black"
//                       color="black"
//                       p={3}
//                       mb={3}
//                       opacity="0.9"
//                       mr={3}
//                       fontSize="1.2rem"
//                       type="button"
//                       border="1px"
//                       // _hover={{ bg: "white", color: "black" }}
//                     >
//                       {image.price}
//                     </Button>
//                   </a>
//                   {/*
//                   <a href="https://wwws.airfrance.ro/travel-guide/destinations">
//                     <Button
//                       position="absolute"
//                       bottom={0}
//                       left="50%"
//                       transform="translateX(-50%)"
//                       size="sm"
//                       background="white"
//                       // colorScheme="black"
//                       color="black"
//                       p={3}
//                       mb={3}
//                       opacity="0.9"
//                       mr={3}
//                       fontSize="1.2rem"
//                       type="button"
//                       border="1px"
//                       // _hover={{ bg: "white", color: "black" }}
//                     >
//                       Călătorește acum cu Air France!
//                     </Button>
//                   </a> */}
//                 </GridItem>
//               </Box>
//             );
//           })}
//         </Grid>
//       </Flex>
//     </>
//   );
// };

import React, { useState } from "react";
import {
  Box,
  Grid,
  Image,
  Button,
  Heading,
  VStack,
  Container,
  Text,
  Flex,
  useBreakpointValue,
  keyframes,
  ScaleFade,
} from "@chakra-ui/react";

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 10px #00a3ff; }
  50% { box-shadow: 0 0 20px #00a3ff; }
  100% { box-shadow: 0 0 10px #00a3ff; }
`;

export const GridPromoAfNov = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const columns = useBreakpointValue({ base: 1, md: 2, lg: 4 });
  const imageHeight = useBreakpointValue({ base: "250px", lg: "400px" });
  const headingSize = useBreakpointValue({ base: "2xl", lg: "3xl" });

  const destinations = [
    {
      title: "PARIS(CDG)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Paris.jpg",
    },
    {
      title: "HELSINKI(HEL)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Helsinki.jpg",
    },
    {
      title: "MADRID(MAD)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Madrid.jpg",
    },
    {
      title: "BORDEAUX(BOD)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Bordeaux.jpg",
    },
    {
      title: "TOULOUSE(TLS)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Toulouse.jpg",
    },
    {
      title: "PORTO(OPO)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Porto.jpg",
    },
    {
      title: "MARSEILLE(MRS)",
      price: "Verifică acum!",
      thumbnail:
        "https://travel-time.ro/images/af-kl/af-feb-2025/Marseille.jpg",
    },
    {
      title: "NICE(NCE)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Nice.jpg",
    },
  ];

  return (
    <Box bgGradient="linear(to-br, blue.500, black)" minH="100vh" py={8} px={4}>
      <Container maxW="container.xl">
        {/* Header Section */}
        <VStack spacing={6} mb={12}>
          <Box
            bg="whiteAlpha.100"
            // backdropFilter="blur(10px)"
            p={4}
            rounded="xl"
            w={{ base: "full", md: "container.sm" }}
          >
            <Image
              src="/images/af-kl/Air France-logo.png"
              alt="Air France Logo"
              mx="auto"
              h="50px"
              objectFit="contain"
            />
          </Box>

          <Box
            h="2px"
            w="250px"
            bgGradient="linear(to-r, blue.400, cyan.300)"
            rounded="full"
          />
        </VStack>

        {/* Grid Section */}
        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={6}>
          {destinations.map((destination, index) => (
            <Box
              key={destination.title}
              position="relative"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              transition="transform 0.3s"
              _hover={{ transform: "scale(1.02)" }}
            >
              <Box
                bg="whiteAlpha.100"
                // backdropFilter="blur(8px)"
                rounded="xl"
                overflow="hidden"
                position="relative"
                animation={`${glowAnimation} 3s infinite`}
              >
                {/* Title */}
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  p={4}
                  bgGradient="linear(to-b, blackAlpha.800, transparent)"
                  zIndex={2}
                >
                  <Text fontSize="xl" fontWeight="bold" color="white">
                    {destination.title}
                  </Text>
                </Box>

                {/* Image */}
                <Box h={imageHeight} overflow="hidden">
                  <Image
                    src={destination.thumbnail}
                    alt={destination.title}
                    w="full"
                    h="full"
                    objectFit="cover"
                    transition="transform 0.5s"
                    _hover={{ transform: "scale(1.1)" }}
                  />
                </Box>

                {/* Price Button */}
                <Flex
                  position="absolute"
                  bottom={4}
                  left={0}
                  right={0}
                  justify="center"
                >
                  <ScaleFade in={hoveredIndex === index}>
                    <a href="https://tbs.flytime.ro/reseller/auth/">
                      <Button
                        bg="whiteAlpha.900"
                        color="blue.900"
                        _hover={{
                          bg: "blue.500",
                          color: "white",
                        }}
                        rounded="full"
                        px={6}
                        fontWeight="bold"
                        transition="all 0.3s"
                        // backdropFilter="blur(4px)"
                      >
                        {destination.price}
                      </Button>
                    </a>
                  </ScaleFade>
                </Flex>
              </Box>
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default GridPromoAfNov;
